import React from 'react';
import './App.css';
//HEADER / FOOTER
import Header from './header';
import Footer from './footer'
//PAGES
import Home from './home';
import Contact from './contact';
import About from './about';
//CONFIG
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//RFCE

function App() {
  return (
    <div>
      <Helmet>
            <title>HOME</title>
      </Helmet>
      <Header />
      <Route exact path="/" component={Home}/>
      <Route exact path="/about" component={About}/>
      <Route exact path="/contact" component={Contact}/>
  
      <Footer />
    </div>
  );
}

export default App;
