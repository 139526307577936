import React from 'react';
import Button from './button';
import {Helmet} from 'react-helmet';

function about() {
    return (
        <div>
        <Helmet>
            <title>ABOUT</title>
        </Helmet>
        <div className="container">
        <img src="https://unsplash.it/205" alt="test"/>
        <h1>
            THIS IS ABOUT
        </h1>
        <Button name="doma" url='/'/>
        </div>
        </div>
    )
}

export default about
