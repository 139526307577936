import React from 'react';
import {Helmet} from 'react-helmet';

function contact() {
    return (
        <div>
        <Helmet>
            <title>CONTACT</title>
        </Helmet>
        <div className="container">
        <img src="https://unsplash.it/200" alt="test"/>
        <h1>
            THIS IS CONTACT
        </h1>
        </div>
        </div>
    )
}

export default contact
