import React from 'react'

function footer() {
    return (
        <div className="footer">
            THIS IS FOOTER
        </div>
    )
}

export default footer
