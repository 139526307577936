import React, { useState } from 'react';

function Button(props) {
    const [name] = useState(props.name)
    const [url] = useState(props.url)
    return (
        <a href={url} rel="noopener noreferer">
        <button>
           {name}
        </button>
        </a>
    )
}

export default Button
