import React from 'react';
import Button from './button';

function home() {
    return (
        <div className="container">
            <img src="https://unsplash.it/210" alt="test"/>
            <h1>
                THIS IS HOME
            </h1>
            <Button name="ovo je gumb za about" url='/about'/>
        </div>
        
    )
}

export default home
