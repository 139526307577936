import React from 'react';
import {Link} from 'react-router-dom';
 
function header() {
    return (
        <header className="header" onClick={thisClick}>
        <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
        </ul>
    </header>
    )
}

function thisClick(){
  console.log('radi')
}

export default header

//RFCE


